<template>
  <div class="mt-1 mx-3">
    <custom-attributes> </custom-attributes>
  </div>
</template>

<script>
import CustomAttributes from "@/components/profile/custom-attributes/index.vue";

import { mapActions } from "vuex";

export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    CustomAttributes,
  },
  created() {
    this.notifyQueue({
      text: "Profilo",
      path: "",
      level: 1,
    });
    this.notifyQueue({
      text: "Campi Aggiuntivi",
      path: "attributes.index",
      level: 2,
    });
  },
};
</script>
