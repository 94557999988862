<template>
  <div class="mt-2">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Generali" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="title"
                        name="Nome Campo"
                        label="Nome Campo"
                        v-model="form[rep].title"
                        placeholder="Inserisci una descrizione"
                        :rules="getRules('title')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="entity"
                        name="Sezione"
                        label="Sezione"
                        v-model="form[rep].entity"
                        :options="beForm[rep].entity.options"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="getRules('entity')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="is_searchable"
                        :name="beForm[rep].is_searchable.label"
                        :label="beForm[rep].is_searchable.label"
                        v-model="form[rep].is_searchable"
                        :options="beForm[rep].is_searchable.options"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="getRules('is_searchable')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="is_sortable"
                        :name="beForm[rep].is_sortable.label"
                        :label="beForm[rep].is_sortable.label"
                        v-model="form[rep].is_sortable"
                        :options="beForm[rep].is_sortable.options"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="getRules('is_sortable')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="data_type_id"
                        :name="beForm[rep].data_type_id.label"
                        :label="beForm[rep].data_type_id.label"
                        v-model="form[rep].data_type_id"
                        :options="beForm[rep].data_type_id.options"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="getRules('data_type_id')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="is_hidden"
                        :name="beForm[rep].is_hidden.label"
                        :label="beForm[rep].is_hidden.label"
                        v-model="form[rep].is_hidden"
                        :options="beForm[rep].is_hidden.options"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="getRules('is_hidden')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                  >Reset</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="outline-lisaweb"
                  size="sm"
                  class="btn-reset float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "custom_attribute",
      id: this.$route.params.id,
      form: {
        custom_attribute: {
          title: null,
          entity: null,
          section: null,
          data_type_id: null,
          is_searchable: null,
          is_sortable: null,
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.store(this.repository)
        .then(() => {
          this.$store.dispatch("auth/attributes");
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: campo aggiuntivo creato`,
          });
          this.shortcut(
            "attributes.index",
            null,
            null,
            "filterCustomAttributes"
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.isLoading = true;
    this.fetchCreateForm(this.repository).then(() => {
      console.log(this.beForm);
      this.isLoading = false;
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
